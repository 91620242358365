<template>
  <div id="todo-app" class="border border-solid d-theme-border-grey-light rounded relative overflow-hidden">
    <!--sidebar-->
    <vs-sidebar class="items-no-padding" parent="#todo-app" :click-not-close="sidebar.clickNotClose" :hidden-background="sidebar.clickNotClose" v-model="sidebar.active">
      <component :is="scrollbarTag" class="todo-scroll-area" :settings="{ maxScrollbarLength: 60, wheelSpeed: 0.30 }">
        <div class="px-6">
          <div class="vx-card vx-col w-full mb-2 shadow-none">
            <label class="ml-1 text-xs">Proyek</label>
            <vs-select autocomplete v-model="sidebar.proyek.selected" class="w-full">
              <vs-select-item v-for="(it, index) in sidebar.proyek.items" :key="index" :value="it" :text="it.nama"/>
            </vs-select>
          </div>
        </div>
      </component>
    </vs-sidebar>

    <!--box-->
    <div :class="{'sidebar-spacer': sidebar.clickNotClose}" class="no-scroll-content border border-r-0 border-b-0 border-t-0 border-solid d-theme-border-grey-light no-scroll-content">
      <div class="flex d-theme-dark-bg items-center border border-l-0 border-r-0 border-t-0 border-solid d-theme-border-grey-light">
        <!--toggle sidebar-->
        <feather-icon class="md:inline-flex lg:hidden ml-4 mr-4 cursor-pointer" icon="MenuIcon" @click.stop="toggleSidebar(true)"/>

        <!--search -->
        <div class="w-full flex">
          <div class="flex items-center cursor-pointer" @click="toggleCheckAll">
            <vs-checkbox class="whitespace-no-wrap text-sm px-2 pointer-events-none"
                       :checked="isCheckAll"
                       @input="toggleCheckAll($event)"
                       size="small">
            Check All
          </vs-checkbox>
          </div>

          <vs-input icon-no-border
                    size="large"
                    icon-pack="feather"
                    icon="icon-search"
                    placeholder="Cari"
                    v-model="search"
                    @keyup.enter="onSearch"
                    class="vs-input-no-border vs-input-no-shdow-focus w-6/12 sm:w-10/12"/>

          <vs-checkbox class="whitespace-no-wrap text-sm px-2"
                       v-model="isShowCheckedOnly"
                       @input="clearBox(); getDataBox();"
                       size="small">
            Show Checked Only
          </vs-checkbox>
        </div>
      </div>

      <!--main content -->
      <component :is="scrollbarTag" class="todo-content-scroll-area" :settings="{ maxScrollbarLength: 60, wheelSpeed: 0.30 }">
        <div class="w-full" :class="{'px-2 pt-4': box.errors !== null}">
          <ValidationErrors :errors="box.errors"/>
        </div>
        <p v-if="isBoxEmpty" class="p-10 text-center">{{ box.loading ? 'Loading...' : 'Tidak ada data.' }}</p>

        <div class="w-full p-2" :style="{'opacity-50 pointer-events-none': box.loading}">
          <!--------data-------->
          <div>
            <div v-for="item in box.items" :key="item.id" class="vx-card p-6 rounded-none mb-2">
              <div class="vx-row">
                <div class="vx-col w-5/6 flex sm:items-center sm:flex-row flex-col">
                  <div class="flex items-center">
                    <vs-checkbox class="w-8 m-0 vs-checkbox-small" v-model="item.is_checked" @input="onCheckedChange($event, item)"/>
                    <h5 class="todo-title">
                      {{ item.nama }}
                    </h5>
                    <p class="text-primary ml-2" v-if="item.username">({{ item.username }})</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--------next page button-------->
          <div v-if="box.pagination.isHasNextPage" class="w-full flex items-center justify-center">
            <p class="p-4 w-full text-center cursor-pointer hover:font-bold" @click="loadMore">
              {{ box.loading ? 'Loading...' : 'Load More' }}
            </p>
          </div>
        </div>
      </component>

    </div>

    <!--modals-->
    <vs-popup title="Konfirmasi" :active.sync="modalConfirmCheckAll.isActive">
      <div class="vx-row">
        <div class="vx-col">
          <p>Semua data rekanan akan di-set ke proyek {{ sidebar.proyek.selected.nama }}. Aksi ini mungkin akan memerlukan waktu. Yakin ingin melanjutkan?</p>
        </div>
      </div>
      <div class="vx-row float-right">
        <div class="vx-col w-full mt-10">
          <vs-button class="mr-3" type="border" @click="modalConfirmCheckAll.isActive = !modalConfirmCheckAll.isActive">Batal</vs-button>
          <vs-button :disabled="modalConfirmCheckAll.isLoading" @click="assignAllRekananToProyek">
            <span v-if="modalConfirmCheckAll.isLoading" class="animate-pulse">Menyimpan...</span>
            <span v-if="!modalConfirmCheckAll.isLoading">Lanjutkan</span>
          </vs-button>
        </div>
      </div>
    </vs-popup>

    <vs-popup title="Konfirmasi" :active.sync="modalConfirmCancelCheckAll.isActive">
      <div class="vx-row">
        <div class="vx-col">
          <p>Semua data rekanan akan di-unset dari proyek {{ sidebar.proyek.selected.nama }}. Aksi ini mungkin akan memerlukan waktu. Yakin ingin melanjutkan?</p>
        </div>
      </div>
      <div class="vx-row float-right">
        <div class="vx-col w-full mt-10">
          <vs-button class="mr-3"
                     color="danger"
                     type="border"
                     @click="modalConfirmCancelCheckAll.isActive = !modalConfirmCancelCheckAll.isActive">
            Batal
          </vs-button>
          <vs-button color="danger"
                     :disabled="modalConfirmCancelCheckAll.isLoading"
                     @click="removeAllRekananFromProyek">
            <span v-if="modalConfirmCancelCheckAll.isLoading" class="animate-pulse">Menyimpan...</span>
            <span v-if="!modalConfirmCancelCheckAll.isLoading">Lanjutkan</span>
          </vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import RekananRepository from '@/repositories/master/rekanan-repository'
import ProyekRepository from '@/repositories/master/proyek-repository'
import vSelect from 'vue-select'

export default {
  name: 'RekananSetProyek',
  components: {
    ValidationErrors,
    VuePerfectScrollbar,
    'v-select': vSelect
  },
  mounted () {
    this.getAllProyek()
  },
  created () {
    this.setSidebarWidth()
  },
  data () {
    return {
      search: '',
      isShowCheckedOnly: false,
      isCheckAll: false,
      sidebar: {
        clickNotClose: true,
        active: true,
        loading: false,
        proyek: {
          items: [],
          selected: {}
        }
      },
      box: {
        errors: null,
        loading: false,
        items: [],
        pagination: {
          perPage: 25,
          currentPage: 1,
          isHasNextPage: false
        }
      },
      modalConfirmCheckAll: {
        isActive: false,
        isLoading: false
      },
      modalConfirmCancelCheckAll: {
        isActive: false,
        isLoading: false
      }
    }
  },
  watch: {
    'sidebar.proyek.selected' () {
      this.refreshDataBox()
    },
    windowWidth () {
      this.setSidebarWidth()
    }
  },
  computed: {
    isBoxEmpty () {
      return this.box.items.length < 1
    },
    isUnsavedChanges () {
      return this.box.unsaved
    },
    scrollbarTag () {
      return this.$store.getters['theme/scrollbarTag']
    },
    windowWidth () {
      return this.$store.state.theme.windowWidth
    }
  },
  methods: {
    onCheckedChange (isChecked, item) {
      const idProyek = this.sidebar.proyek.selected.id
      if (isChecked) {
        this.assignToProyek(idProyek, [item.id])
      } else {
        this.removeFromProyek(idProyek, [item.id])
      }
    },

    getDataBox () {
      this.box.loading = true

      const params = {
        page: this.box.pagination.currentPage,
        perPage: this.box.pagination.perPage,
        search: this.search,
        id_proyek: this.isShowCheckedOnly ? this.sidebar.proyek.selected.id : null,
        check_is_checked_for_id_proyek: this.sidebar.proyek.selected.id
      }

      RekananRepository.all(params)
        .then(response => {
          const items = response.data.data
          this.box.items.push(...items)
          this.box.pagination.isHasNextPage = (items.length === this.box.pagination.perPage)
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.box.loading = false
        })
    },

    assignToProyek (idProyek, listIdRekanan) {
      RekananRepository.assignToProyek({
        id_proyek: idProyek,
        list_id_rekanan: [...listIdRekanan]
      })
        .then(response => {})
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    },

    toggleCheckAll () {
      const isCheckAll = !this.isCheckAll
      if (isCheckAll) {
        this.modalConfirmCheckAll.isActive = true
      } else {
        this.modalConfirmCancelCheckAll.isActive = true
      }
    },

    assignAllRekananToProyek () {
      this.modalConfirmCheckAll.isLoading = true

      const idProyek = this.sidebar.proyek.selected.id
      RekananRepository.assignAllRekananToProyek({
        id_proyek: idProyek
      })
        .then(response => {
          this.isCheckAll = !this.isCheckAll
          this.clearBox()
          this.getDataBox()
          this.notifySuccess('Berhasil tersimpan.')
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.modalConfirmCheckAll.isLoading = false
          this.modalConfirmCheckAll.isActive = false
        })
    },

    removeAllRekananFromProyek () {
      this.modalConfirmCancelCheckAll.isLoading = true

      const idProyek = this.sidebar.proyek.selected.id
      RekananRepository.removeAllRekananFromProyek({
        id_proyek: idProyek
      })
        .then(response => {
          this.isCheckAll = !this.isCheckAll
          this.clearBox()
          this.getDataBox()
          this.notifySuccess('Berhasil tersimpan.')
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.modalConfirmCancelCheckAll.isLoading = false
          this.modalConfirmCancelCheckAll.isActive = false
        })
    },

    removeFromProyek (idProyek, listIdRekanan) {
      RekananRepository.removeFromProyek({
        id_proyek: idProyek,
        list_id_rekanan: [...listIdRekanan]
      })
        .then(response => {})
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    },

    refreshDataBox () {
      this.clearBox()
      this.getDataBox()
      this.box.unsaved = false
    },

    loadMore () {
      this.box.pagination.currentPage += 1
      this.getDataBox()
    },

    clearBox () {
      this.box.pagination.currentPage = 1
      this.box.pagination.isHasNextPage = false
      this.box.items = []
    },

    getAllProyek () {
      this.sidebar.loading = true

      ProyekRepository.getAll()
        .then(response => {
          this.sidebar.proyek.items = response.data.data
          if (this.sidebar.proyek.items.length > 0) {
            this.sidebar.proyek.selected = this.sidebar.proyek.items[0]
          }
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.sidebar.loading = false
        })
    },

    setSidebarWidth () {
      if (this.windowWidth < 992) {
        this.sidebar.active = this.sidebar.clickNotClose = false
      } else {
        this.sidebar.active = this.sidebar.clickNotClose = true
      }
    },

    toggleSidebar (value = false) {
      if (!value && this.sidebar.clickNotClose) return
      this.sidebar.active = value
    },

    onSearch () {
      this.clearBox()
      this.getDataBox()
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/vuexy/apps/todo.scss";
</style>
